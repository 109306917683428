import PropTypes from 'prop-types'
import React from 'react'

import {
    Box,
    Flex,
    Text,
    Link as ChakraLink
} from '@salesforce/retail-react-app/app/components/shared/ui'
import Link from '../../../components/link'
import {colors} from '../../../constants'

const IngredientCard = ({isBanner = false, data, index, total, clickable}) => {
    const cta = data?.cta

    const isLastCard = index === total - 1
    const isFirstCard = index === 0
    return (
        <Flex
            position="relative"
            borderRadius={{base: '0', lg: '12px'}}
            borderBottomLeftRadius={{
                lg: isFirstCard ? '0' : '12px'
            }}
            borderTopLeftRadius={{
                lg: isFirstCard ? '0' : '12px'
            }}
            borderBottomRightRadius={{
                lg: isLastCard ? '0' : '12px'
            }}
            borderTopRightRadius={{
                lg: isLastCard ? '0' : '12px'
            }}
            overflow="hidden"
            height="100%"
            padding={{base: '64px 72px', lg: '147px 153px'}}
            alignItems="flex-end"
            minHeight={{base: '498px', lg: '700px'}}
            backgroundColor="#F9F9F6"
            as={ChakraLink}
            href={clickable && cta.url}
            textDecoration="none"
            _hover={{textDecoration: 'none'}}
        >
            <Box
                height="full"
                bg={
                    data.media
                        ? `url(${data.media.desktop.url})`
                        : data.theme?.backgroundColor
                }
                borderRadius={{base: '0px', lg: '12px'}}
                borderBottomLeftRadius={{
                    lg: isFirstCard ? '0' : '12px'
                }}
                borderTopLeftRadius={{
                    lg: isFirstCard ? '0' : '12px'
                }}
                borderBottomRightRadius={{
                    lg: isLastCard ? '0' : '12px'
                }}
                borderTopRightRadius={{
                    lg: isLastCard ? '0' : '12px'
                }}
                width="100%"
                position="absolute"
                top="0"
                left="0"
                backgroundPosition="center"
                backgroundSize="cover"
            />
            <Flex
                justifyContent="space-between"
                alignItems="center"
                flexDirection="column"
                width="100%"
                gap={{base: '20px', lg: '40px'}}
                position="relative"
            >
                <Text
                    color={colors.white}
                    fontSize={
                        isBanner
                            ? {base: '32px', md: '40px', lg: '52px', xl: '58px'}
                            : {base: '32px', md: '22px', lg: '30px'}
                    }
                    maxWidth="100%"
                    fontFamily="Raleway"
                    fontWeight="300"
                    textColor={isBanner ? colors.black : colors.black}
                    lineHeight={
                        isBanner
                            ? {base: '39.49px', lg: '70px'}
                            : {base: '37px', md: '28px'}
                    }
                    align="center"
                >
                    {isBanner ? data.title : data.name}
                </Text>
                <Text
                    color={colors.white}
                    fontSize={{base: '16px', md: '20px', lg: '24px'}}
                    maxWidth="100%"
                    fontFamily="Raleway"
                    fontWeight="400"
                    textColor={colors.black}
                    align="center"
                    dangerouslySetInnerHTML={{__html: data.description}}
                ></Text>
                <Flex width="fit-content">
                    <Link
                        {...cta}
                        buttonStyles={{
                            backgroundColor: colors.transparent,
                            fontFamily: 'Roboto',
                            fontWeight: '300',
                            fontSize: {base: '10px', lg: '20px'},
                            textDecoration: 'underline',
                            _hover: {
                                backgroundColor: colors.transparent
                            },
                            color: cta?.theme?.color
                        }}
                        type="tertiary"
                    />
                </Flex>
            </Flex>
        </Flex>
    )
}

IngredientCard.displayName = 'IngredientCard'

IngredientCard.propTypes = {
    isBanner: PropTypes.bool,
    index: PropTypes.number,
    total: PropTypes.number,
    clickable: PropTypes.bool,
    data: PropTypes.shape({
        name: PropTypes.string,
        cta: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
            externalUrl: PropTypes.string,
            openLinkInNewTab: PropTypes.bool,
            theme: PropTypes.shape({
                backgroundColor: PropTypes.string,
                color: PropTypes.string
            }),
            type: PropTypes.string,
            isExternal: PropTypes.bool
        }),
        title: PropTypes.string,
        media: PropTypes.shape({
            desktop: PropTypes.shape({
                url: PropTypes.string
            })
        }),
        theme: PropTypes.shape({
            backgroundColor: PropTypes.string
        }),
        description: PropTypes.string
    })
}

export default IngredientCard
