import {contentfulQueryKeys} from '../../../constants'
import {getPageQuery} from '../queries'
import {useContentfulQueryWithHook} from './use-contentful-query.hook'

export const usePageQuery = (slug) => {
    const {data, error} = useContentfulQueryWithHook(
        contentfulQueryKeys.getPageQuery,
        getPageQuery,
        {slug}
    )

    const filteredQuery =
        data?.pageCollection?.items[0]?.sectionsCollection?.items

    return {filteredQuery, error}
}
