import PropTypes from 'prop-types'
import React from 'react'
import ReactPlayer from 'react-player'

import {
    Box,
    Image,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'

import {styleConfigChakraKeys} from '../../constants'

const HeroImageContainer = ({media, autoplayVideo}) => {
    const styles = useMultiStyleConfig(styleConfigChakraKeys.heroSection)

    const [playing, setPlaying] = React.useState(true)

    return (
        <>
            {media?.contentType === 'video/mp4' ? (
                <Box
                    {...styles.heroImageContainer}
                    onClick={() => setPlaying(!playing)}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                    sx={{'.videoPlayer video': {objectFit: 'cover'}}}
                >
                    <ReactPlayer
                        className="videoPlayer"
                        url={media.url}
                        loop
                        muted={autoplayVideo}
                        playing={playing}
                        width="100%"
                        height="100%"
                        playsinline
                    />
                </Box>
            ) : (
                <Box {...styles.heroImageContainer}>
                    <Image
                        width="100%"
                        height="100%"
                        src={media.url}
                        alt="hero image"
                        objectFit="cover"
                    />
                </Box>
            )}
        </>
    )
}

HeroImageContainer.propTypes = {
    media: PropTypes.shape({
        contentType: PropTypes.string,
        url: PropTypes.string
    }),
    autoplayVideo: PropTypes.bool
}

export default HeroImageContainer
